$('.js-complete-enrollment').on('click', function(e) {

  e.preventDefault();

  var self = $(this);
  var loadingText = self.data('loading-text');
  var defaultText = self.data('default-text');
  self.html(loadingText).attr('disabled', 'disabled');

  $.ajax({
    method: 'POST',
    url: '/wp/wp-admin/admin-post.php',
    data: { action: 'save_enrollment' },
    success: function(data) {

      if(typeof data.status !== 'undefined'){
        if(data.status == 'success') {
          console.log('Received success status and the following data:');
          console.log(data);

          if(parseInt($('.js-enrollment-confirmation').data('enrollment-price')) > 0 && $('.js-enrollment-confirmation').data('enrollment-language') != 'pt_br') {

            let cybersourceformdata = data.cyber_source_fields;
            $('#js-cybersource-form').html(cybersourceformdata).submit();

          }
          else {
            console.log('Free Enrollment');
            let enrollmentID = data.enrollment_id;

            $.ajax({
              method: 'POST',
              url: '/wp/wp-admin/admin-post.php',
              data: { action: 'finalize_free', enrollmentID: enrollmentID },
              success: function(data) {

                if(data.status == 'done') {
                  window.location.href = '/receipt?req_transaction_uuid=' + enrollmentID;
                }
                else {
                  console.log('Could not finalize a free enrollment!');
                }

              },
            });

          }
        }
        else {
          console.log('Could not save the enrollment to the DB.  Debug data:');
          console.log(data);
          self.html(defaultText).removeAttr('disabled');
        }
      }
      else {
        console.log('Something went wrong getting the response');
        self.html(defaultText).removeAttr('disabled');
      }
    },
  });

});
