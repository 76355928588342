/* eslint-disable */

var gov_club = $('#js-gov-enrollment-form').data('club-name');
var gov_res_fee_amt = parseInt($('#js-gov-enrollment-form').data('default-fee'));
var gov_currency_name = $('#js-gov-enrollment-form').data('currency-name');
var gov_currency_amounts = '';
var gov_total_currency_used = 0;
var nf = new Intl.NumberFormat();

$('.js-dynamic-currency').html(gov_currency_name);
$('.js-res-cover-amt').html(nf.format(gov_res_fee_amt))

$('.js-club-selection').on('change', function() {

  var gov_selector = $(this);
  gov_club = $(this).val();
  gov_res_fee_amt = $(this).find(':selected').data('currency-cover-amt'); // Amount of the reservation fee, in points
  gov_currency_name = $(this).find(':selected').data('currency');
  gov_currency_amounts = $(this).find(':selected').data('currency-options');
  gov_total_currency_used = 0;

  gov_selector.attr('aria-busy', true);

  console.log('Currency Name: ' + gov_currency_name);
  console.log('Reservation Cover Amount: ' + gov_res_fee_amt);

  $('.js-dynamic-currency').html(gov_currency_name);
  $('.js-res-cover-amt').html(nf.format(gov_res_fee_amt));

  $('#amount_to_gift').empty();

  if(gov_club != '') {

    // Populate the gifting options
    var array = gov_currency_amounts.split(",");
    var dllist = $('#amount_to_gift');

    $.each(array,function(i){
        dllist.append(
            $('<option></option>').val(array[i]).html(nf.format(array[i]) + ' ' + gov_currency_name)
        );
    });

    $('.js-hide-until-club-chosen').slideDown( 'slow', function() {
      gov_selector.attr('aria-busy', false);
      $('.js-first-focus').focus();
    });
  }
  else {
    $('.js-hide-until-club-chosen').slideUp( 'slow', function() {
      gov_selector.attr('aria-busy', false);
    });
  }

  gov_total_currency_used = calculatePointsUsed();

});

function calculatePointsUsed() {

  let points_selected = 0;
  points_selected = parseInt($('#amount_to_gift').val());

  if($('#js-Yes-cover_res_fee').is(':checked')) {
    points_selected = points_selected + gov_res_fee_amt;
  }

  $('.js-currency-used').html(nf.format(points_selected));

  return points_selected

}

$('#amount_to_gift').on('change', function() {
  calculatePointsUsed();
});

$('#js-Yes-cover_res_fee').on('change', function() {
  calculatePointsUsed();
});

$('#js-No-cover_res_fee').on('change', function() {
  calculatePointsUsed();
});

/* eslint-enable */
