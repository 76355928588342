$('.js-apply-promocode').on('click', function(e) {

  e.preventDefault();

  var self = $(this);

  applyPromoCode(self);

});

$('.js-promocode-field').on('keypress', function(e) {

  if (e.which === 13) {

    e.preventDefault();

    var self = $('.js-apply-promocode').first();

    applyPromoCode(self);

  }

});

function applyPromoCode(self) {

  self.html(self.data('loading-text')).attr('disabled', 'disabled');
  self.attr('aria-busy', true);
  $('.js-promo-container').attr('aria-busy', true);
  var promoCode = self.closest('.row').find('.js-promocode-field').val();

  $('.js-invalid-promo').attr('aria-hidden', true).removeAttr('role').hide();
  $('.js-form-alert').hide();
  $('.js-form-alert-error-response').hide();
  $('input').removeAttr('aria-invalid');
  $('select').removeAttr('aria-invalid');
  $('textarea').removeAttr('aria-invalid');
  $('.missing').removeClass('missing');
  $('.missing-section').removeClass('missing-section');

  console.log('The promo code is: ' + promoCode);

  $.ajax({
    method: 'POST',
    url: '/wp/wp-admin/admin-post.php',
    data: { action: 'apply_promo', promocode: promoCode },
    success: function(data) {

      if(typeof data.status !== 'undefined'){
        if(data.status == 'success') {
          console.log('Received promo code information for valid promo code:');
          console.log(data);

          let message = $('.js-promo-code-applied-message').data('message');
          message = message.replace(':CODE:', promoCode);

          $('.js-promo-code-applied-message').html('<p>' + message + '</p>').focus();

          /* Update the pricing */

          $('.js-term-selector option:gt(0)').remove();

          let pricing_data = [];

          if(data.promo_details.allow_combo == true) {
            pricing_data = data.promo_details.combo_pricing;
          }
          else {
            pricing_data = data.promo_details.standard_pricing;
          }

          console.log('Pricing Data:');
          console.log(pricing_data);

          $.each(pricing_data, function(key,value) {

            let keyText = value.term_years + ' ' + $('.js-promo-container').data('text-years') + ' - $' + value.price;

            if(parseInt(value.term_years) === 1) {
              keyText = '1 ' + $('.js-promo-container').data('text-year') + ' - $' + value.price;
            }

            if(value.type !== undefined) {
              // On Family Share
              let membership_type = '(' + capitalizeFirstLetter(value.type) + ' Membership)';
              keyText = keyText.replace('$', membership_type + ' - $');
            }

            console.log('Update Drop Down With Data: ' + keyText);

            if(value.type !== undefined) {
              $('.js-term-selector').append($('<option></option>')
                 .attr('value', value.term_years + '-' + value.type).text(keyText + ' USD'));
            }
            else {
              $('.js-term-selector').append($('<option></option>')
                 .attr('value', value.term_years).text(keyText + ' USD'));
            }

          });

          $('.js-promo-code-applied').show().attr('aria-hidden', false);
          $('.js-promo-code-entry').hide().attr('aria-hidden', true);

          // Combo Destinations
          if(data.promo_details.allow_combo == true) {

            // Need to load in the destinations
            let destinations = data.promo_details.combo_destinations;
            let destinationsHTML = '';
            let counter = 1;

            console.log('Destinations:');
            console.log(destinations);

            $.each(destinations, function(key,value) {

              destinationsHTML += '<div class="destination"><div class="form-field-wrapper checkbox-wrapper"><input id="destinations-' + counter + '" name="destinations[]" value="' + key + '" type="checkbox"><label for="destinations-' + counter + '">' + value + '</label></div></div>';
              counter++;

            });

            console.log('Destinations HTML');
            console.log(destinationsHTML);

            $('.js-destinations-marketing').html(data.promo_details.destinations_marketing);
            $('.js-destinations').html(destinationsHTML);
            $('.js-combo-showhide').show();
            $('.js-reset-on-no-combo').attr('required', 'required');
            $('.js-combo-enrollment-fields').show();

            if(data.promo_details.destinations_marketing !== undefined && data.promo_details.destinations_marketing != '') {
              $('.js-promo-marketing-copy-container').html(data.promo_details.destinations_marketing);
              $('.js-promo-marketing-copy').show();
            }

          }
          else {
            $('.js-destinations').html('');
            $('.js-reset-on-no-combo').val('');
            $('.js-combo-showhide').hide();
            $('.js-destinations-marketing').html('');
            $('.js-combo-enrollment-fields').hide();
            $('.js-promo-marketing-copy-container').html('');
            $('.js-promo-marketing-copy').hide();
          }

        }
        else {
          console.log('Promo code invalid');
          $('.js-invalid-promo').attr('role', 'alert').removeAttr('aria-hidden').show();
        }
      }
      else {
        console.log('Something went wrong getting the promo code details');
      }

      self.html(self.data('default-text')).removeAttr('disabled').removeAttr('aria-busy');
      $('.js-promo-container').removeAttr('aria-busy');

    },
  });

}

$('.js-remove-promocode').on('click', function(e) {

  e.preventDefault();

  var self = $(this);
  self.html('Loading...').attr('disabled', 'disabled');

  $('.js-form-alert-error-response').hide();
  $('input').removeAttr('aria-invalid');
  $('select').removeAttr('aria-invalid');
  $('textarea').removeAttr('aria-invalid');
  $('.missing').removeClass('missing');
  $('.missing-section').removeClass('missing-section');

  $.ajax({
    method: 'POST',
    url: '/wp/wp-admin/admin-post.php',
    data: { action: 'remove_promo' },
    success: function(data) {

      if(typeof data.status !== 'undefined'){
        if(data.status == 'success') {
          console.log('Promo code removed and original pricing returned:');
          console.log(data);

          $('.js-term-selector option:gt(0)').remove();

          $.each(data.pricing.standard, function(key,value) {

            let keyText = '';

            if(key.includes('-')) {

              // Family Share, have to split out weeks / points

              if(key.includes('points')) {
                keyText = key + ' Years (Points Membership) - $' + value + ' USD';

                if(parseInt(key) === 1) {
                  keyText = '1 Year (Points Membership) - $' + value + ' USD';
                }
              }
              else {
                keyText = key + ' Years (Weeks Membership) - $' + value + ' USD';

                if(parseInt(key) === 1) {
                  keyText = '1 Year (Weeks Membership) - $' + value + ' USD';
                }
              }

            }
            else {

              keyText = key + ' ' + $('.js-promo-container').data('text-years') + ' - $' + value + ' USD';

              if(parseInt(key) === 1) {
                keyText = '1 ' + $('.js-promo-container').data('text-year') + ' - $' + value + ' USD';
              }

            }

            console.log('Update Drop Down With Data: ' + keyText);

            $('.js-term-selector').append($('<option></option>')
               .attr('value', key).text(keyText));
          });

          $('.js-combo-enrollment-fields').hide();
          $('.js-destinations').html('');
          $('.js-reset-on-no-combo').val('').removeAttr('required');
          $('.js-combo-showhide').hide();
          $('.js-destinations-marketing').html('');

          $('.js-promo-code-applied').hide().attr('aria-hidden', true);
          $('.js-promo-code-entry').show().attr('aria-hidden', false);

          $('.js-promocode-field').val('').focus();
        }
        else {
          console.log('Promo could not be removed');
        }
      }
      else {
        console.log('Something went wrong removing the promo code');
      }

      self.html('Remove').removeAttr('disabled');

    },
  });

});

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

