$('.js-complete-gov-enrollment').on('click', function(e) {

  e.preventDefault();

  var self = $(this);
  self.html('Loading...').attr('disabled', 'disabled');

  $.ajax({
    method: 'POST',
    url: '/wp/wp-admin/admin-post.php',
    data: { action: 'save_gov_enrollment' },
    success: function(data) {

      if(typeof data.status !== 'undefined'){
        if(data.status == 'success') {

          window.location.href = '/receipt';

        }
        else {
          console.log('Did not get a valid response back from the handler');
          console.log(data);
          self.html('Confirm Enrollment').removeAttr('disabled');
        }
      }
      else {
        console.log('Something went wrong getting the response');
        self.html('Confirm Enrollment').removeAttr('disabled');
      }
    },
  });

});
