$(document).ready(function () {
  $('.js-accordion').accordion();
});

(function ($) {
  $.fn.accordion = function () {
    $(this).each(function () {
      var $accordion = $(this);

      $accordion.find('.active').show();

      $accordion.on('click', '.js-carat-click', function (event) {
        openAndClose($(event.currentTarget));
      });

    });

    function openAndClose(element){

      var $content = $(element).parent().find('.js-accordion-content'),
          $activeContent = $(element).parent().find('.js-accordion-content:visible');

        if ($content.is($activeContent)) {
          $content.removeClass('active').slideUp('fast');
          $content.parent().removeClass('active');
          $(element).attr('aria-expanded', 'false');
          $content.prev().removeClass('active');
          $content.attr('aria-hidden', 'true');
        } else {
          $content.addClass('active').slideDown('fast');
          $content.parent().addClass('active');
          $content.attr('aria-hidden', 'false');
          $content.prev().addClass('active');
          $(element).attr('aria-expanded', 'true');
        }
    }
  };
}(window.jQuery));
