/* Accessible Navigation Menu */

$(document).ready(function () {

  if($('.the-primary-nav-wrapper').length > 0) {
    $('.menu-primary-navigation-container').hide();
    $('.js-mobile-menu-toggle').removeClass('hidden').attr('aria-hidden', 'false');
  }

});

$('.js-has-children').on('click', function() {

  var children = $(this).parent().find('.js-second-level-nav');

  openCloseDesktopNav($(this), children);

});

/* Dropdowns for desktop navigation */

function openCloseDesktopNav(nav, children) {
  if($(nav).hasClass('open')) {
    $(nav).removeClass('open');
    $(children).slideUp( 'fast', function() {
      $(children).removeClass('open');
      $(nav).parent().find('.js-open-submenu').removeClass('open').attr('aria-expanded', 'false');
      $(nav).parent().find('.js-open-submenu').attr('aria-label', ($(nav).parent().find('.js-open-submenu').data('open-text')));
      $(nav).parent().find('.js-open-submenu').find('.js-sr-text').html($(nav).parent().find('.js-open-submenu').data('open-text'));
      $(nav).attr('aria-expanded', 'false');
    });
  }
  else {

    if(!menuIsMobile()){
      closeDesktopMenuItems(nav);
    }

    $(children).slideDown( 'fast', function() {
      $(children).addClass('open');
      $(nav).addClass('open');
      $(nav).attr('aria-expanded', 'true');
      $(nav).parent().find('.js-open-submenu').addClass('open').attr('aria-expanded', 'true');
      $(nav).parent().find('.js-open-submenu').find('.js-sr-text').html($(nav).parent().find('.js-open-submenu').data('close-text'));
      $(nav).parent().find('.js-open-submenu').attr('aria-label', $(nav).parent().find('.js-open-submenu').data('close-text'));
    });
  }
}

/* Close Desktop Menu Items */

function closeDesktopMenuItems(nav) {
  $('.js-second-level-nav').removeClass('open');
  $('.js-second-level-nav').slideUp('fast', function() {
    $('.js-has-children').find('.js-open-submenu').removeClass('open').attr('aria-expanded', 'false');
    $('.js-has-children').find('.js-open-submenu').attr('aria-label', ($(nav).parent().find('.js-open-submenu').data('open-text')));
    $('.js-has-children').find('.js-open-submenu').find('.js-sr-text').html($(nav).parent().find('.js-open-submenu').data('open-text'));
    $('.js-has-children').attr('aria-expanded', 'false');
    $('.js-has-children').removeClass('open');
    $('.js-open-submenu').removeClass('open').attr('aria-label', $('.js-open-submenu').first().data('open-text'));
    $('.js-open-submenu').removeClass('open').find('.js-sr-text').html($('.js-open-submenu').first().data('open-text'));
  });
}

/* Mobile navigation burger */

$('.js-mobile-menu-toggle').on('click', function() {
  openCloseBurger($(this));
});

function openCloseBurger(burger) {
  var container = $('.js-main-navigation').parent();

  if($(burger).hasClass('open')) {
    $(burger).removeClass('open');
    $(burger).attr('aria-expanded', 'false');
    container.slideUp('fast');
  }
  else {
    $(burger).addClass('open');
    $(burger).attr('aria-expanded', 'true');
    container.slideDown('fast');
  }
}

/* Open and close sub-menu items on mobile */

$('.js-open-submenu').on('click', function() {
  openCloseSubmenu($(this));
});

function openCloseSubmenu(toggle) {

  var labelText = '';

  if($(toggle).hasClass('open')) {
    $(toggle).removeClass('open');
    labelText = $(toggle).data('open-text');
    $(toggle).attr('aria-label', labelText);
    $(toggle).find('.js-sr-text').html(labelText);
    $(toggle).parent().find('.js-second-level-nav').slideUp('fast');
    $(toggle).attr('aria-expanded', 'false');
    $(toggle).parent().find('.js-has-children').removeClass('open').attr('aria-expanded', 'false');
  }
  else {
    $(toggle).addClass('open');
    labelText = $(toggle).data('close-text');
    $(toggle).attr('aria-label', labelText);
    $(toggle).find('.js-sr-text').html(labelText);
    $(toggle).parent().find('.js-second-level-nav').slideDown('fast');
    $(toggle).attr('aria-expanded', 'true');
    $(toggle).parent().find('.js-has-children').addClass('open').attr('aria-expanded', 'true');
  }
}

function menuIsMobile() {
  if($('.mobile-detector').css('opacity') == '0') {
    return false;
  }
  else {
    return true;
  }
}

/* Keyboard Actions for Nav Items */

$(document).keyup(function(e) {

  var $focused = $(':focus'); // The element that has keyboard focus

  if(e.which == 9 && menuIsMobile() === false) {
    /* User tabbed to a different nav item */

    if($($focused).hasClass('first-level-link') || $($focused).hasClass('js-has-children') || $($focused).hasClass('custom-logo-link')) {

      if(!$($focused).hasClass('js-has-children')) {
        closeDesktopMenuItems($('.js-has-children'));
      }
    }
  }

  if(e.which == 32 || e.which == 13) {
    /* Space or Enter should open / close the menu */

    if($focused.hasClass('js-has-children')) {
      openCloseDesktopNav($focused, $($focused).parent().find('.js-second-level-nav'));
    }
  }

});
