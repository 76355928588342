/* Busy button for a form that does a traditional POST request */
/* Checks that all 'required' elements are filled out, then disables the button if validation succeeds */

$(function () {
    $('.js-post-form').submit(function () {

        var required = $('input,textarea,select').filter('[required]:visible');

        var allRequired = true;
        required.each(function(){
            if($(this).val() == ''){
                allRequired = false;
            }
        });

        if (allRequired === true) {
            var button = $(this).find('.js-submit-btn');
            
            if($(button).data('loading-text') != undefined && $(button).data('loading-text') != '') {
              $(button).html($(button).data('loading-text'));
            }
            else {
              $(button).html('Loading...');
            }

            $(button).attr('disabled', 'disabled');
        }
    });
});

/* Submit a form via AJAX */

$(function () {
    $('.js-ajax-form').submit(function (e) {

        e.preventDefault();

        console.log('Submitting AJAX Form...');

        var form = $(this);
        var url = form.attr('action');

        $('.js-form-alert').hide().removeAttr('aria-role');
        $('.form-control').removeAttr('aria-invalid');
        $(form).attr('aria-busy', true);

        var required = form.find('input,textarea,select').filter('[required]:visible');

        var allRequired = true;
        required.each(function(){
            if($(this).val() == ''){
                allRequired = false;
            }
        });

        if (allRequired === true) {
            var button = $(this).find('.js-submit-btn');
            
            if($(button).data('loading-text') != undefined && $(button).data('loading-text') != '') {
              $(button).html($(button).data('loading-text'));
            }
            else {
              $(button).html('Loading...');
            }

            $(button).attr('disabled', 'disabled');
            $(form).addClass('submitting');

            $.ajax({
              type: 'POST',
              url: url,
              data: form.serialize(), // serializes the form's elements.
              success: function(data)
              {
                if(typeof data.status !== 'undefined'){
                  if(data.status == 'success') {
                    $('.js-form-alert-success').show().attr('aria-role', 'alert');
                    $(form).hide().attr('aria-hidden', true);

                    if(Boolean(data.redirect) && data.redirect != '') {
                      location.href = data.redirect;
                    }

                  }
                  else {
                    console.log('Form has error state');
                    console.log('Error HTML:');
                    console.log(data.error_html);
                    $('.js-error-contents').html('The following errors occurred:' + data.error_html).parent().show().attr('aria-role', 'alert');
                    $('html, body').animate({scrollTop: $('.js-error-contents').first().offset().top -150 }, 'slow');
                    $(button).removeAttr('disabled');
                    $(button).html($(button).data('text'));

                    // Mark our invalid fields

                    if(data.invalid_fields != undefined) {

                      console.log('Invalid Fields:');
                      console.log(data.invalid_fields);

                      $('.form-control').each(function(){
                          if(data.invalid_fields.fields.includes($(this).attr('name'))){
                              console.log('Field Invalid: ' + $(this).attr('name'));
                              $(this).addClass('invalid');
                              $(this).attr('aria-invalid', true);
                          }
                      });

                    }

                    $(form).attr('aria-busy', false);
                    $(form).removeClass('submitting');
                  }
                }
                else {
                  $('.js-form-alert-error-response').html('An error occurred while submitting the form.').show().role('aria-role', 'alert');
                  $('html, body').animate({scrollTop: $('.js-form-alert-error-response').first().offset().top -150 }, 'slow');
                  $(button).removeAttr('disabled');
                  $(button).html($(button).data('text'));
                  $(form).removeClass('submitting');
                }
              },
          });
        }
        else {
          $('.js-form-alert-required-fields').show().attr('aria-role', 'alert');
        }
    });
});

/* Listen in on textareas and update the character count */
$('.js-count-characters').on('change keyup paste', function() {

  var el = $(this);
  var max = el.attr('maxlength');
  var countElement = el.parent().find('.js-chars-remaining');
  var used = el.val().length;
  var remaining = max - used;

  countElement.html(remaining + ' characters remaining');

});

/* Mask Phone Numbers and Money Values using jquery-mask.js */

$(document).ready(function(){
  $('.phone-input').not('.unmask').mask('000-000-0000');
  $('.money-input').mask('#,##0', {reverse: true});
  $('.unmask').unmask();
});

/* Print the page */

$('.js-print-page').on('click', function() {
  window.print();
});
